<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="PlaceOrder"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab2TableUp>
            <div>
              <!-- トラック/トラクタ/トレーラ -->
              <!-- 許可証情報-->
              <BaseLabel :columnInfo="detailInfo.permit_request_info" />
              <BaseTable
                select-mode="single"
                ref="tablePermits"
                @row-selected="onRowSelectedPermit"
                :fields="fieldsPermit"
                :items="tablePermits"
              />
            </div>
          </template>

          <template v-slot:tab2TableDown>
            <!-- 許可車両情報-->
            <BaseLabel :columnInfo="detailInfo.permit_vehicle_info" />
            <BaseTable
              select-mode="range"
              ref="tablePermitVehicles"
              :fields="fieldsPermitVehicle"
              :items="tablePermitVehicles"
            />
            <!-- 許可場所情報-->
            <BaseLabel :columnInfo="detailInfo.permit_route_place_info" />
            <BaseTable
              select-mode="range"
              ref="tablePermitPlaces"
              :fields="fieldsPermitPlace"
              :items="tablePermitPlaces"
            />
          </template>

          <!-- 配車結果明細情報 -->
          <template v-slot:tab1TableDown>
            <div id="BaseView">
              <section data-class="work-list-table">
                <div>
                  <BaseButton
                    class="edit-button"
                    variant="success"
                    :disabled="!isSelectedItem"
                    :columnInfo="detailCtrlInfo.add_cost"
                    icon="plus-circle"
                    @click="tableRowAdd(obj)"
                  />
                </div>

                <!-- テーブル -->
                <!-- <BaseTable
                  select-mode="single"
                  @row-selected="onRowSelectedItem"
                  :fields="fields"
                  :items="tableItems"
                /> -->
                <b-table
                  data-class="base-table"
                  class="table table-bordered base-table"
                  select-mode="single"
                  bordered
                  hover
                  no-border-collapse
                  responsive
                  small
                  sticky-header
                  selectable
                  outlined
                  :fields="fields"
                  :items="tableItems"
                  @row-selected="onRowSelectedItem"
                  v-bind="$attrs"
                  v-on="$listeners"
                >
                  <template v-slot:cell(order_tariff_product_code)="row">
                    <b-form-input
                      v-model="row.item.order_tariff_product_code"
                      size="sm"
                      type="text"
                      :formatter="maxInputTariffProductCode"
                      v-bind="$attrs"
                      v-on="$listeners"
                    />
                  </template>
                </b-table>
              </section>
            </div>

            <!-- コスト情報 -->
            <div id="BaseView">
              <section data-class="work-list-table">
                <div>
                  <BaseButton
                    class="edit-button"
                    variant="success"
                    :disabled="!isSelectedCost"
                    :columnInfo="detailCtrlInfo.delete"
                    icon="minus-circle"
                    @click="tableRowRemove(obj)"
                  />
                </div>

                <b-table
                  data-class="base-table"
                  class="table table-bordered base-table"
                  select-mode="single"
                  bordered
                  hover
                  no-border-collapse
                  responsive
                  small
                  sticky-header
                  selectable
                  outlined
                  :fields="fieldsCost"
                  :items="tableCosts"
                  @row-selected="onRowSelectedCost"
                  v-bind="$attrs"
                  v-on="$listeners"
                >
                  <template v-slot:cell(amount_money)="row">
                    <b-form-input
                      v-model="row.item.amount_money"
                      size="sm"
                      type="number"
                      :formatter="maxInputNumber"
                      v-bind="$attrs"
                      v-on="$listeners"
                      :disabled="disabledCosts"
                      @input="changeCost(row)"
                    />
                  </template>
                </b-table>
              </section>
            </div>
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>

    <!-- 運賃項目参照(FreightItemReference) Table編集モーダル -->
    <FreightItemModal
      id="freight-item-modal"
      type="entry"
      selectMode="single"
      :selectedSearchCondition="freightItemModalSearchCondition"
      @after-close-set="afterCloseSetFreightItem"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      :event="event"
      @confirm="register(obj)"
      @placeOrder="placeOrder(obj)"
      @approve="approve(obj)"
      @reject="reject(obj)"
      @fareCalculate="fareCalculate(obj)"
      @manualPermit="manualPermit(obj)"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="planning-result-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- Excel出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="excelDownload(obj)"
    />
  </div>
</template>

<script>
import PlaceOrderInfo from './PlaceOrderInfo'
import {
  getCostWithoutTax,
  getListOptions,
  openSubModal,
} from '@/common/Common.js'
import {
  SLOT_NAME,
  NEED_PERMIT_CHECK,
  INTEGER_MAX_MIN,
} from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  clear,
  goRegister,
  register,
  setOrderDetail,
  setPermitRequestDetail,
  tableRowAdd,
  tableRowRemove,
  checkStatusPossibleEdit,
  goPlaceOrder,
  placeOrder,
  goApprove,
  approve,
  goReject,
  reject,
  goFareCalculate,
  fareCalculate,
  goManualPermit,
  manualPermit,
  excelDownload,
} from './PlaceOrderDetail.js'

export default {
  name: 'placeOrderDetail',

  components: {
    FreightItemModal: () => import('@/master/freightItem/FreightItemModal.vue'),
  },
  data() {
    return {
      selectedItem: [],
      selectedCost: [],
      selectedPermit: [],
      tableItems: [],
      tableCosts: [],
      tablePermits: [], //許可証依頼情報
      tablePermitVehicles: [], //許可証車両
      tablePermitPlaces: [], //許可証場所
      PlaceOrder: new PlaceOrderInfo(),
      obj: this,
      infoMessage: null,
      registerMessage: null,
      // ConfirmModal用イベント
      event: null,
      oriData: null,
      //oriTableCosts: [],
      freightItemModalSearchCondition: {},
      reportId: null,
    }
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    planningResultId: {
      type: Number,
    },
    screenKbn: {
      type: Number,
    },
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLACE_ORDER_DETAIL
      )('detail_ctrl_info')
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLACE_ORDER_DETAIL
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.PLACE_ORDER_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 運賃計算ボタン
          {
            variant: 'success',
            icon: 'list-alt',
            columnInfo: this.detailCtrlInfo.fare_calculate,
            //disabled: true,
            onClickCallback: () => {
              goFareCalculate(this)
            },
          },
          // 発注ボタン
          {
            variant: 'success',
            icon: 'list-alt',
            columnInfo: this.detailCtrlInfo.place_order,
            //disabled: !this.isSelected,
            onClickCallback: () => {
              goPlaceOrder(this)
            },
          },
          // 承認ボタン
          {
            variant: 'success',
            icon: 'copy',
            columnInfo: this.detailCtrlInfo.approve,
            //disabled: !this.isSelected,
            onClickCallback: () => {
              goApprove(this)
            },
          },
          // 拒否ボタン
          {
            variant: 'success',
            icon: 'copy',
            columnInfo: this.detailCtrlInfo.reject,
            //disabled: !this.isSelected,
            onClickCallback: () => {
              goReject(this)
            },
          },
          // 特車許可了承ボタン
          {
            variant: 'success',
            icon: 'copy',
            columnInfo: this.detailCtrlInfo.manual_permit_pass,
            disabled: !this.isDisabledPermit,
            onClickCallback: () => {
              goManualPermit(this)
            },
          },
          // 輸送依頼書出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.detailCtrlInfo.excel,
            onClickCallback: () => {
              this.reportId = 'NP001'
              openSubModal(this, 'download-modal')
            },
          },
        ],
      ]
    },

    inputComponents() {
      return [
        {
          // 詳細情報タブ
          label: this.detailInfo.tab1.label,
          slot: SLOT_NAME.TAB1_TABLE_DOWN,
          inputComponents: [
            {
              // 配車結果番号
              type: 'text',
              id: 'planningResultId',
              columnInfo: this.detailInfo.planning_result_id,
            },
            {
              // ブロック
              type: 'text',
              id: 'blockName',
              columnInfo: this.detailInfo.block_name,
            },
            {
              // 出発日時
              type: 'text',
              id: 'departureDateTime',
              columnInfo: this.detailInfo.departure_date_time,
            },
            //order - departure
            {
              // 出発日付 (Begin)
              type: 'date',
              id: 'orderDepartureBeginDate',
              columnInfo: this.detailInfo.order_departure_begin_date,
            },
            {
              // 出発日時 (Begin)
              type: 'time',
              id: 'orderDepartureBeginTime',
              columnInfo: this.detailInfo.order_departure_begin_time,
            },
            {
              // 出発日付 (End)
              type: 'date',
              id: 'orderDepartureEndDate',
              columnInfo: this.detailInfo.order_departure_end_date,
            },
            {
              // 出発日時 (End)
              type: 'time',
              id: 'orderDepartureEndTime',
              columnInfo: this.detailInfo.order_departure_end_time,
            },

            {
              // 出発場所名
              type: 'text',
              id: 'departurePlaceName',
              columnInfo: this.detailInfo.departure_place_name,
            },
            {
              // 出発場所住所
              type: 'text',
              id: 'departurePlaceAddress',
              columnInfo: this.detailInfo.departure_place_address,
            },
            {
              // 到着日時
              type: 'text',
              id: 'arrivalDateTime',
              columnInfo: this.detailInfo.arrival_date_time,
            },
            //order - arrival
            {
              // 到着日付 (Begin)
              type: 'date',
              id: 'orderArrivalBeginDate',
              columnInfo: this.detailInfo.order_arrival_begin_date,
            },
            {
              // 到着日時 (Begin)
              type: 'time',
              id: 'orderArrivalBeginTime',
              columnInfo: this.detailInfo.order_arrival_begin_time,
            },
            {
              // 到着日付 (End)
              type: 'date',
              id: 'orderArrivalEndDate',
              columnInfo: this.detailInfo.order_arrival_end_date,
            },
            {
              // 到着日時 (End)
              type: 'time',
              id: 'orderArrivalEndTime',
              columnInfo: this.detailInfo.order_arrival_end_time,
            },

            {
              // 到着場所名
              type: 'text',
              id: 'arrivalPlaceName',
              columnInfo: this.detailInfo.arrival_place_name,
            },
            {
              // 到着場所住所
              type: 'text',
              id: 'arrivalPlaceAddress',
              columnInfo: this.detailInfo.arrival_place_address,
            },
            {
              // トリップ番号
              type: 'text',
              id: 'tripNo',
              columnInfo: this.detailInfo.trip_no,
            },
            {
              // ドロップ数
              type: 'text',
              id: 'lastDropNo',
              columnInfo: this.detailInfo.last_drop_no,
            },
            {
              // 輸送距離
              // 配車結果→輸送依頼（transport_distance → transport_request_distance）
              type: 'text',
              id: 'transportRequestDistance',
              columnInfo: this.detailInfo.transport_request_distance,
              // rules: [
              //   this.INPUT_TYPE_CHECK.REQUIRED,
              //   this.INPUT_TYPE_CHECK.FLOAT,
              //   this.INPUT_TYPE_CHECK.FLOAT,
              // ],
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                //this.INPUT_TYPE_CHECK.REQUIRED, //NULL許容
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 理由
              type: 'text',
              id: 'reason',
              columnInfo: this.detailInfo.reason,
            },
            // {
            //   // 評価運賃
            //   type: 'text',
            //   id: 'evaluationCharge',
            //   columnInfo: this.detailInfo.evaluation_charge,
            //   rules: [
            //     this.INPUT_TYPE_CHECK.REQUIRED,
            //     this.INPUT_TYPE_CHECK.NUMERIC,
            //   ],
            //   maxLength: 11,
            // },
            {
              // 距離取得元
              type: 'text',
              id: 'distanceSourceName',
              columnInfo: this.detailInfo.distance_source_name,
            },
            {
              // 荷姿名(代表)
              type: 'text',
              id: 'productName',
              columnInfo: this.detailInfo.product_name,
            },
            {
              // 総数量
              type: 'text',
              id: 'totalQuantity',
              columnInfo: this.detailInfo.total_quantity,
            },
            {
              // 総質量
              type: 'text',
              id: 'totalWeight',
              columnInfo: this.detailInfo.total_weight,
            },
            {
              // 総容量
              type: 'text',
              id: 'totalVolume',
              columnInfo: this.detailInfo.total_volume,
            },
            {
              // 配車ステータス
              type: 'text',
              id: 'planningStatusName',
              columnInfo: this.detailInfo.planning_status_name,
            },
            {
              // 仮車区分
              type: 'text',
              id: 'managementVehicleKindName',
              columnInfo: this.detailInfo.management_vehicle_kind_name,
            },
            //actual_status
            {
              // 配車対象日
              type: 'text',
              id: 'planningTargetDate',
              columnInfo: this.detailInfo.planning_target_date,
            },
            {
              // 確定日時
              type: 'text',
              id: 'planningDecisionDateTime',
              columnInfo: this.detailInfo.planning_decision_date_time,
            },
            {
              // 計画有無フラグ
              type: 'text',
              id: 'planFlagName',
              columnInfo: this.detailInfo.plan_flag_name,
            },
            {
              // 車両稼働ID
              type: 'text',
              id: 'vehicleOperationId',
              columnInfo: this.detailInfo.vehicle_operation_id,
            },
            {
              // 依頼車両
              type: 'referenceCodeName',
              codeId: 'vehicleName',
              nameId: 'requestVehicleNumberplate',
              codeColumnInfo: this.detailInfo.vehicle_name,
              nameColumnInfo: this.detailInfo.request_vehicle_numberplate,
            },
            {
              // 依頼車型
              type: 'text',
              id: 'vehicleModelName',
              columnInfo: this.detailInfo.vehicle_model_name,
            },
            {
              // 依頼輸送手段カテゴリ
              type: 'text',
              id: 'vehicleCategoryName',
              columnInfo: this.detailInfo.vehicle_category_name,
            },
            {
              // 依頼運送会社
              type: 'text',
              id: 'carrierName',
              columnInfo: this.detailInfo.carrier_name,
            },
            //-------------------->>
            // 状態（輸送依頼状態）
            {
              type: 'text',
              id: 'statusName',
              columnInfo: this.detailInfo.status_name,
            },

            {
              // 承認依頼日時
              type: 'text',
              id: 'approvalRequestDatetimeDisplay',
              columnInfo: this.detailInfo.approval_request_datetime_display,
            },
            {
              // 承認依頼ユーザ
              type: 'referenceCodeName',
              codeId: 'approvalRequestUser',
              nameId: 'approvalRequestUserName',
              codeColumnInfo: this.detailInfo.approval_request_user,
              nameColumnInfo: this.detailInfo.approval_request_user_name,
            },
            {
              // 承認依頼コメント
              type: 'text',
              id: 'approvalRequestComment',
              columnInfo: this.detailInfo.approval_request_comment,
            },
            {
              // 承認日時
              type: 'text',
              id: 'approvedDatetimeDisplay',
              columnInfo: this.detailInfo.approved_datetime_display,
            },
            {
              // 承認ユーザ
              type: 'referenceCodeName',
              codeId: 'approvedUser',
              nameId: 'approvedUserName',
              codeColumnInfo: this.detailInfo.approved_user,
              nameColumnInfo: this.detailInfo.approved_user_name,
            },
            {
              // 差戻理由コード
              type: 'select',
              id: 'remandCode',
              columnInfo: this.detailInfo.remand_code,
              options: getListOptions(this.MASTER_CODE.SEND_BACK_COMMENT),
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 差戻理由
              type: 'text',
              id: 'remandComment',
              columnInfo: this.detailInfo.remand_comment,
              maxLength: 255,
            },

            {
              // 承諾日時
              type: 'text',
              id: 'answerDatetimeDisplay',
              columnInfo: this.detailInfo.answer_datetime_display,
            },
            {
              // 承諾ユーザ
              type: 'referenceCodeName',
              codeId: 'answerUser',
              nameId: 'answerUserName',
              codeColumnInfo: this.detailInfo.answer_user,
              nameColumnInfo: this.detailInfo.answer_user_name,
            },
            {
              // 拒否理由コード
              type: 'select',
              id: 'refusalCode',
              columnInfo: this.detailInfo.refusal_code,
              options: getListOptions(this.MASTER_CODE.REFUSAL_CODE),
              onChangeCallback: () => {},
            },
            {
              // 拒否理由
              type: 'text',
              id: 'refusalComment',
              columnInfo: this.detailInfo.refusal_comment,
              //maxLength: 255,
            },

            // {
            //   // 実車番登録ステータス
            //   type: 'text',
            //   id: 'realVehicleEntryStatusName',
            //   columnInfo: this.detailInfo.real_vehicle_entry_status_name,
            // },
            // {
            //   // 実車番依頼日時
            //   type: 'text',
            //   id: 'realVehicleRequestTime',
            //   columnInfo: this.detailInfo.real_vehicle_request_time,
            // },
            // {
            //   // 実車番登録日時
            //   type: 'text',
            //   id: 'realVehicleEntryTime',
            //   columnInfo: this.detailInfo.real_vehicle_entry_time,
            // },
            // {
            //   // 実車両
            //   type: 'referenceCodeName',
            //   codeId: 'realVehicleName',
            //   nameId: 'realVehicleNumberplate',
            //   codeColumnInfo: this.detailInfo.real_vehicle_name,
            //   nameColumnInfo: this.detailInfo.real_vehicle_numberplate,
            // },
            // {
            //   // 電話番号
            //   type: 'text',
            //   id: 'realTel',
            //   columnInfo: this.detailInfo.real_tel,
            // },
            // {
            //   // 辞退理由区分
            //   type: 'text',
            //   id: 'resignReasonKindName',
            //   columnInfo: this.detailInfo.resign_reason_kind_name,
            // },
            // {
            //   // 実車番コメント
            //   type: 'text',
            //   id: 'realVehicleComment',
            //   columnInfo: this.detailInfo.real_vehicle_comment,
            // },
            // {
            //   // 依頼者
            //   type: 'text',
            //   id: 'realVehicleRequestUserName',
            //   columnInfo: this.detailInfo.real_vehicle_request_user_name,
            // },
            // {
            //   // 承諾者
            //   type: 'text',
            //   id: 'realVehicleEntryUserName',
            //   columnInfo: this.detailInfo.real_vehicle_entry_user_name,
            // },
            // 配車結果予備１ - 配車結果予備１0
            {
              // 配車結果予備１
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
              maxLength: 50,
            },
            {
              // 配車結果予備２
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
              maxLength: 50,
            },
            {
              // 配車結果予備３
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
              maxLength: 50,
            },
            {
              // 配車結果予備４
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
              maxLength: 50,
            },
            {
              // 配車結果予備５
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
              maxLength: 50,
            },
            {
              // 配車結果予備６
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
              maxLength: 50,
            },
            {
              // 配車結果予備７
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
              maxLength: 50,
            },
            {
              // 配車結果予備８
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
              maxLength: 50,
            },
            {
              // 配車結果予備９
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
              maxLength: 50,
            },
            {
              // 配車結果予備１０
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
              maxLength: 50,
            },

            //輸送依頼予備１ - 輸送依頼予備１0
            //
            {
              //輸送依頼予備9
              type: 'select',
              id: 'transportReserve09',
              columnInfo: this.detailInfo.transport_reserve09,
              options: getListOptions(this.MASTER_CODE.PAYMENT_TERMS, false),
              onChangeCallback: () => {},
            },
            {
              //輸送依頼予備3
              type: 'text',
              id: 'transportReserve03',
              columnInfo: this.detailInfo.transport_reserve03,
              maxLength: 50,
            },
            {
              //輸送依頼予備１0
              type: 'text',
              id: 'transportReserve10',
              columnInfo: this.detailInfo.transport_reserve10,
              maxLength: 50,
            },
            {
              //輸送依頼予備１
              type: 'text',
              id: 'transportReserve01',
              columnInfo: this.detailInfo.transport_reserve01,
              maxLength: 50,
            },
            {
              //輸送依頼予備2
              type: 'text',
              id: 'transportReserve02',
              columnInfo: this.detailInfo.transport_reserve02,
              maxLength: 50,
            },
            {
              //輸送依頼予備4
              type: 'text',
              id: 'transportReserve04',
              columnInfo: this.detailInfo.transport_reserve04,
              maxLength: 50,
            },
            {
              //輸送依頼予備5
              type: 'text',
              id: 'transportReserve05',
              columnInfo: this.detailInfo.transport_reserve05,
              maxLength: 50,
            },
            {
              //輸送依頼予備6
              type: 'text',
              id: 'transportReserve06',
              columnInfo: this.detailInfo.transport_reserve06,
              maxLength: 50,
            },
            {
              //輸送依頼予備7
              type: 'text',
              id: 'transportReserve07',
              columnInfo: this.detailInfo.transport_reserve07,
              maxLength: 50,
            },
            {
              //輸送依頼予備8
              type: 'text',
              id: 'transportReserve08',
              columnInfo: this.detailInfo.transport_reserve08,
              maxLength: 50,
            },
            {
              //注意事項[受注] #2748
              type: 'text',
              id: 'warningMessage',
              columnInfo: this.detailInfo.warning_message,
              maxLength: 50,
            },
            //------------------------<<
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
          ],
        },
        {
          // 許可証情報タブ
          label: this.detailInfo.tab2.label,
          slot: SLOT_NAME.TAB2_TABLE_UP,
          slot2: SLOT_NAME.TAB2_TABLE_DOWN,
          inputComponents: [
            // 実車両(TR)
            {
              // トラック/トラクタ (TR)
              type: 'referenceCodeName',
              codeId: 'realVehicleId',
              nameId: 'realVehicleNumberplate',
              codeColumnInfo: this.detailInfo.real_vehicle_id,
              nameColumnInfo: this.detailInfo.real_vehicle_numberplate,
              //btnColumnInfo: this.detailInfo.sub_modal_real_vehicle,
              onClickCallback: () => {},
            },
            // 実車両(TL)
            {
              // トレーラー (TL)
              type: 'referenceCodeName',
              codeId: 'realTrailerId',
              nameId: 'realTrailerNumberplate',
              codeColumnInfo: this.detailInfo.real_trailer_id,
              nameColumnInfo: this.detailInfo.real_trailer_numberplate,
              //btnColumnInfo: this.detailInfo.sub_modal_real_trailer,
              onClickCallback: () => {},
            },

            // Detail
            {
              // 運送会社
              type: 'referenceCodeName',
              codeId: 'detailCarrierCode',
              nameId: 'detailCarrierName',
              codeColumnInfo: this.detailInfo.detail_carrier_code,
              nameColumnInfo: this.detailInfo.detail_carrier_name,
              onClickCallback: () => {},
            },
            {
              // 許可証ID
              type: 'text',
              id: 'detailPassagePermitId',
              columnInfo: this.detailInfo.detail_passage_permit_id,
            },
            {
              // 許可番号
              type: 'text',
              id: 'detailPermitNo',
              columnInfo: this.detailInfo.detail_permit_no,
            },
            {
              // 有効開始日付
              type: 'text',
              id: 'detailValidBeginDate',
              columnInfo: this.detailInfo.detail_valid_begin_date,
            },
            {
              // 有効終了日付
              type: 'text',
              id: 'detailValidEndDate',
              columnInfo: this.detailInfo.detail_valid_end_date,
            },
            {
              // 通行区分
              type: 'select',
              id: 'detailOnewayOrRound',
              columnInfo: this.detailInfo.detail_oneway_or_round,
              options: getListOptions(this.MASTER_CODE.ONEWAY_OR_ROUND),
              onChangeCallback: () => {},
            },
            {
              // 誘導者有無
              type: 'select',
              id: 'detailNeedGuide',
              columnInfo: this.detailInfo.detail_need_guide,
              options: getListOptions(this.MASTER_CODE.NEED_GUIDE),
              onChangeCallback: () => {},
            },
            {
              // 通行可能時間帯（From）
              type: 'text',
              id: 'detailPermitTimeFrom',
              columnInfo: this.detailInfo.detail_permit_time_from,
            },
            {
              // 通行可能時間帯（To）
              type: 'text',
              id: 'detailPermitTimeTo',
              columnInfo: this.detailInfo.detail_permit_time_to,
            },
            {
              // 長さL
              type: 'text',
              id: 'detailLength',
              columnInfo: this.detailInfo.detail_length,
            },
            {
              // 幅W
              type: 'text',
              id: 'detailWidth',
              columnInfo: this.detailInfo.detail_width,
            },
            {
              // 総重量
              type: 'text',
              id: 'detailHeight',
              columnInfo: this.detailInfo.detail_height,
            },
            {
              // 幅W
              type: 'text',
              id: 'detailWeight',
              columnInfo: this.detailInfo.detail_weight,
            },
            {
              // 高速使用有無(高速利用可)
              type: 'select',
              id: 'detailUseHighway',
              columnInfo: this.detailInfo.detail_use_highway,
              options: getListOptions(this.MASTER_CODE.HIGHWAY_USE_YN_FLAG),
              onChangeCallback: () => {},
            },

            // detail_first_permit_no	2	新規時許可証番号
            // detail_previous_permit_no	2	前回許可証番号
            // detail_reserve01	2	予備1（支払済フラグ）
            // detail_reserve02	2	予備2（支払処理日付）
            {
              // ルートの説明
              type: 'text',
              id: 'detailRouteDescription',
              columnInfo: this.detailInfo.detail_route_description,
            },
            {
              // 経路数
              type: 'text',
              id: 'detailRouteCount',
              columnInfo: this.detailInfo.detail_route_count,
            },
            {
              // 総通行経路数
              type: 'text',
              id: 'detailTotalRouteCount',
              columnInfo: this.detailInfo.detail_total_route_count,
            },
            {
              // 担当部門
              type: 'referenceCodeName',
              codeId: 'detailChargeDepartmentCode',
              nameId: 'detailChargeDepartmentName',
              codeColumnInfo: this.detailInfo.detail_charge_department_code,
              nameColumnInfo: this.detailInfo.detail_charge_department_name,
              onClickCallback: () => {},
            },
            {
              // 新規時許可証番号
              type: 'text',
              id: 'detailFirstPermitNo',
              columnInfo: this.detailInfo.detail_first_permit_no,
            },
            {
              // 前回許可証番号
              type: 'text',
              id: 'detailPreviousPermitNo',
              columnInfo: this.detailInfo.detail_previous_permit_no,
            },
            {
              // 予備1（支払済フラグ）
              type: 'text',
              id: 'detailReserve01',
              columnInfo: this.detailInfo.detail_reserve01,
            },
            {
              // 予備1（支払処理日付）
              type: 'text',
              id: 'detailReserve02',
              columnInfo: this.detailInfo.detail_reserve02,
            },
          ],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLACE_ORDER_DETAIL
      )(this.DOMAIN_NAME.FIELDS)
    },
    fieldsCost() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLACE_ORDER_DETAIL
      )(this.DOMAIN_NAME.FIELDS + '_cost')
    },
    fieldsPermit() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLACE_ORDER_DETAIL
      )(this.DOMAIN_NAME.FIELDS + '_permit')
    },
    fieldsPermitVehicle() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLACE_ORDER_DETAIL
      )(this.DOMAIN_NAME.FIELDS + '_permit_vehicle')
    },
    fieldsPermitPlace() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLACE_ORDER_DETAIL
      )(this.DOMAIN_NAME.FIELDS + '_permit_place')
    },

    // /**
    //  * 情報メッセージ.
    //  * @return {String}
    //  */
    // infoMessage() {
    //   return this.$store.getters['init/getMessage'](
    //     'YZ00MG995I',
    //     this.detailCtrlInfo.register.label
    //   )
    // },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelectedItem() {
      //return this.selectedItem.length > 0
      var result = false
      if (this.selectedItem.length > 0) {
        if (checkStatusPossibleEdit(this)) {
          result = true
        }
      }

      return result
    },

    //金額情報
    isSelectedCost() {
      //return this.selectedCost.length > 0
      var result = false
      if (this.selectedCost.length > 0) {
        if (checkStatusPossibleEdit(this)) {
          result = true
        }
      }
      return result
    },
    disabledCosts() {
      return !checkStatusPossibleEdit(this)
    },

    //許可証情報
    isDisabledPermit() {
      var result = false
      // (this.tablePermits)
      // (this.PlaceOrder.permitRequests)
      if (this.PlaceOrder.permitRequests.length > 0) {
        //※need_permit＝０の時は本チェックは実施できない
        if (
          this.PlaceOrder.needPermitCheck != null &&
          this.PlaceOrder.needPermitCheck == NEED_PERMIT_CHECK.NEED_YES
        ) {
          result = true
        }
      }
      return result
    },
    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      let title = null
      if (this.reportId == 'NP001') {
        title = this.detailCtrlInfo.excel.label
      } else {
        title = this.detailCtrlInfo.excel2.label
      }
      return this.$store.getters['init/getMessage']('YZ00MG994Q', title)
    },
  },

  methods: {
    getListOptions,
    register,
    back,
    setOrderDetail,
    setPermitRequestDetail,
    tableRowAdd,
    tableRowRemove,
    placeOrder, //発注
    approve, //承認
    reject, //拒否
    fareCalculate, //運賃計算
    manualPermit, //特車許可了承
    excelDownload,
    maxInputNumber(value) {
      //金額のMaxLength
      return String(value).substring(0, 9)
    },
    maxInputTariffProductCode(value) {
      //50桁のMaxLength
      return String(value).substring(0, 50)
    },

    afterCloseSetFreightItem(selectedItem) {
      setOrderDetail(this.obj, selectedItem)
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelectedItem(selectedItem) {
      this.selectedItem = selectedItem
    },
    // onRowClicked(item, index) {
    //   this.detailRowIndex = index
    // },
    onRowSelectedCost(selectedCost) {
      this.selectedCost = selectedCost
    },
    onRowSelectedPermit(selectedPermit) {
      //this.selectedPermit = selectedPermit
      if (selectedPermit.length > 0) {
        setPermitRequestDetail(this, selectedPermit)
      }
    },

    /**
     * 運賃変更イベント時、処理
     */
    changeCost(changedRow) {
      console.log(changedRow)

      //税抜き金額計算
      let costWithoutTax = getCostWithoutTax(
        changedRow.item.amount_money,
        changedRow.item.tax_percentage
      )

      changedRow.item.cost_without_tax = costWithoutTax
    },
  },
}
</script>
<style lang="scss" scoped>
.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
